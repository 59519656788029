
import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Link, useNavigate } from "react-router-dom";
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import Layout from '../component/layout'
import { makeRequest2 } from '../../../utils/makeRequest';
import { getItem } from '../../../utils/localStorage';
import { addFamilyMemberURL } from '../../../utils/urls'
function AddFamily() {
    const [fullName, setFullName] = useState('');
    const [relation, setRelation] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')

    const navigate = useNavigate()

    const HandleSubmit = async () => {
        try {

            if (fullName === '') {
                throw new Error('Full Name is required.')

            }
            if (relation === '') {
                throw new Error('Relation is required.')
            }
            if (phoneNumber === '') {
                throw new Error('Phone Number is required.')
            }
            const CustomerId = await getItem('selectedCustomerId');


            const response = await makeRequest2(addFamilyMemberURL + '/' + CustomerId, 'POST', {
                fullName, relation, phoneNumber
            })
            if (response.success) {

                toastify(response.data, 'success')
                navigate('/resident/family')
            }
            else {
                throw new Error(response.error)
            }

        }
        catch (err) {
            console.log(err)
            toastify(err.message, 'error')
        }
    }





    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access</Link>
                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/visitor_access/family"}>Family</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Add Family</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/resident/visitor_access/family">
                                <i className="ti ti-arrow-left"></i>
                                &nbsp;
                                Back
                            </Link>

                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Fullname</label>
                                        <input type="text" className="form-control" value={fullName} onChange={(e) => {
                                            setFullName(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Relation</label>
                                        <input type="number" className="form-control" value={relation} onChange={(e) => {
                                            setRelation(e.target.value)
                                        }} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Phone Number</label>
                                        <input type="text" className="form-control" value={phoneNumber} onChange={(e) => {
                                            setPhoneNumber(e.target.value)
                                        }} />
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="card-footer text-end btn-page">

                            <div className="btn btn-primary" onClick={() => {
                                HandleSubmit()
                            }}>Submit</div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}


export default AddFamily