import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';

import { Link, useNavigate } from 'react-router-dom';
import { getItem, clearStorage, setItem } from '../../../utils/localStorage';
import { residentFacilitiessURL } from '../../../utils/urls'
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify} from '../../../utils/toast';
function Header() {
  const navigate = useNavigate()
  const [userName, setFullname] = useState("")
  const [userEmail, setEmail] = useState("")
  const [visibleChangePassword, setVisibleChangePassword] = useState(false)
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const HandleLogOut = async () => {

    await clearStorage()
    navigate('/')
  }
  const getCurrentUser = async () => {
    try {
   

      const currentUserValue = await getItem('RESIDENTUSER')
      if (currentUserValue) {
          const currentUser = JSON.parse(currentUserValue)
          const fullName = currentUser.user.fullName
          const email = currentUser.user.email
          setFullname(fullName)
          setEmail(email)
      }
    }
    catch (err) {
      console.log(err)
    }
  }
  const handleFacilityChange = async (e) => {
    const facilityId = e.target.value;
    const selectedFacility = facilities.find(facility => facility._id === facilityId);
    setSelectedFacility(selectedFacility);
    await setItem('selectedFacilityId', facilityId);
    await setItem('selectedCustomerId', selectedFacility.customerId)
    window.location.href = window.location.pathname

  };
  const fetchResidentFacilities = async () => {
    try {
      const response = await makeRequest2(residentFacilitiessURL, 'GET', {})
      if (response.success) {
        setFacilities(response.data)
        const storedFacilityId = await getItem('selectedFacilityId');
        if (storedFacilityId) {

          setSelectedFacility(response.data.find(facility => facility._id === storedFacilityId));
        }
        else {
          await setItem('selectedFacilityId', response.data[0]._id);
          await setItem('selectedCustomerId', response.data[0].customerId)
          fetchResidentFacilities()
        }

      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      console.log(err)
      toastify(err.message, 'error')
    }
  }
  useEffect(() => {
    fetchResidentFacilities()
    getCurrentUser()

  }, [])
  return (

    <header className="pc-header">
      <div className="header-wrapper">

        <div className="me-auto pc-mob-drp">
          <ul className="list-unstyled">
            <li className="pc-h-item pc-sidebar-collapse">
              <Link to={"#"} className="pc-head-link ms-0" id="sidebar-hide">
                <i className="ti ti-menu-2"></i>
              </Link>
            </li>
            <li className="pc-h-item pc-sidebar-popup">
              <Link to='#' className="pc-head-link ms-0 mobile-collapse">
                <i className="ti ti-menu-2"></i>
              </Link>
            </li>
            <li>

              <div className="d-flex align-items-center justify-content-between mt-4" >
                <select
                  className="form-select rounded-3 form-select-sm w-auto"
                  onChange={handleFacilityChange}
                  value={selectedFacility ? selectedFacility._id : ''}
                >
               
                  {facilities.map(facility => (
                    <option key={facility._id} value={facility._id}>
                      {facility.name}
                    </option>
                  ))}
                </select>
              </div>

            </li>
          </ul>
        </div>
        <div className="ms-auto">
          <ul className="list-unstyled">

            {/* <li className="dropdown pc-h-item">

              <div className="dropdown-menu dropdown-menu-end pc-h-dropdown">
                <Link to={"#!"} className="dropdown-item" onclick="layout_change('dark')">
                  <svg className="pc-icon">
                    <use href="#custom-moon"></use>
                  </svg>
                  <span>Dark</span>
                </Link>
                <Link to={"#!"} className="dropdown-item" onclick="layout_change('light')">
                  <svg className="pc-icon">
                    <use href="#custom-sun-1"></use>
                  </svg>
                  <span>Light</span>
                </Link>
                <Link to={"#!"} className="dropdown-item" onclick="layout_change_default()">
                  <svg className="pc-icon">
                    <use href="#custom-setting-2"></use>
                  </svg>
                  <span>Default</span>
                </Link>
              </div>
            </li> */}

            <li className="dropdown pc-h-item">
              {/* <Link
                className="pc-head-link dropdown-toggle arrow-none me-0"
                data-bs-toggle="dropdown"
                to={"#"}
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <svg className="pc-icon">
                  <use xlinkHref="#custom-notification"></use>
                </svg>
                <span className="badge bg-success pc-h-badge">3</span>
              </Link> */}
              <div className="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown">
                <div className="dropdown-header d-flex align-items-center justify-content-between">
                  <h5 className="m-0">Notifications</h5>
                  <Link to={"#!"} className="btn btn-link btn-sm">Mark all read</Link>
                </div>
                <div className="dropdown-body text-wrap header-notification-scroll position-relative" style={{ maxHeight: `calc(100vh - 215px)`, overflowY: 'scroll' }}>
                  <p className="text-span">Today</p>
                  {/* <div className="card mb-2">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <svg className="pc-icon text-primary">
                            <use xlinkHref="#custom-layer"></use>
                          </svg>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="float-end text-sm text-muted">2 min ago</span>
                          <h5 className="text-body mb-2">UI/UX Design</h5>
                          <p className="mb-0"
                          >Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <svg className="pc-icon text-primary">
                            <use xlinkHref="#custom-sms"></use>
                          </svg>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="float-end text-sm text-muted">1 hour ago</span>
                          <h5 className="text-body mb-2">Message</h5>
                          {/* <p className="mb-0">Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                         */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-span">Yesterday</p>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <svg className="pc-icon text-primary">
                            <use xlinkHref="#custom-sms"></use>
                          </svg>
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <span className="float-end text-sm text-muted">1 hour ago</span>
                          <h5 className="text-body mb-2">Message</h5>
                          {/* <p className="mb-0">Lorem Ipsum has been the industry's standard dummy text ever since the 1500.</p>
                         */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center py-2">
                  <Link to={"#!"} className="link-danger">Clear all Notifications</Link>
                </div>
              </div>
            </li>

            <li className="dropdown pc-h-item header-user-profile">
              <Link
                className="pc-head-link dropdown-toggle arrow-none me-0"
                data-bs-toggle="dropdown"
                to={"#"}
                role="button"
                aria-haspopup="false"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <img src="/assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar" />
              </Link>
              <div className="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown">
                <div className="dropdown-header d-flex align-items-center justify-content-between">
                  <h5 className="m-0">Profile</h5>
                </div>
                <div className="dropdown-body">
                  <div className="profile-notification-scroll position-relative" style={{ maxHeight: `calc(100vh - 225px)` }}>
                    <div className="d-flex mb-1">
                      <div className="flex-shrink-0">
                        <img src="/assets/images/user/avatar-2.jpg" alt="user-image" className="user-avtar wid-35" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="mb-1">{userName}</h6>
                        <span>{userEmail}</span>
                      </div>
                    </div>
                    <hr className="border-secondary border-opacity-50" />

                    {/* <p className="text-span">Manage</p>

                    <Link to="/resident/settings"  className="dropdown-item">
                      <span>
                        <svg className="pc-icon text-muted me-2">
                          <use href="#custom-setting-2"></use>
                        </svg>
                        <span>Settings</span>
                      </span>
                    </Link> */}

                    <hr className="border-secondary border-opacity-50" />
                    <div className="d-grid mb-3">
                      <button className="btn btn-primary" onClick={HandleLogOut}>
                        <svg className="pc-icon me-2">
                          <use href="#custom-logout-1-outline"></use></svg>Logout
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Dialog header="Change Password" visible={visibleChangePassword} style={{ width: '50vw' }} onHide={() => { if (!visibleChangePassword) return; setVisibleChangePassword(false); }}>
        <form>
          <div className="form-group">
            <label>Current Password:</label>
            <input className="form-control mt-2" type="password" required />
          </div>
          <div className="form-group mt-2">
            <label>New Password:</label>
            <input className="form-control mt-2" type="password" required />
          </div>
          <div className="form-group mt-2">
            <label>Confirm Password:</label>
            <input className="form-control mt-2" type="password" required />
          </div>
          <div className="text-end mt-4">
            <button type="submit" className="btn btn-outline-secondary" >
              Clear
            </button>
            &nbsp;
            <button type="submit" className="btn btn-primary" >
              Submit
            </button>
          </div>
        </form>
      </Dialog>
    </header>

  )
}
export default Header