
import React, { useState, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
function SelectMeters() {
  const navigate = useNavigate()
 
  
  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/power_utility"}>Power Utility </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">


                 
                  <div className="col-md-3 col-xs-12 mt-3">
                    
                    <label>Assigned Meter(s)</label>
                    <br />
                    <br/>
                    <select className="form-control">
                        <option value="">-- Select One --</option>
                        <option value="12394032">12394032</option>
                    </select>
                  </div>
                    <div className="col-md-3 col-xs-12 mt-3">
                      <br />
                      <br/>
                      <button className="btn btn-primary" onClick={()=>{
                         navigate('/resident/power_utility/meter_info')
                      }}>View Meter Info</button>
                    </div>
                  

                  </div>

                </div>
               
              </div>
            </div>


          </div>

        </div>
      </div>
    
    </Layout>
  )
}


export default SelectMeters