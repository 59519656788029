import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Layout from '../component/layout'
import { toastify } from '../../../utils/toast'
import { makeRequest2 } from '../../../utils/makeRequest'
import { residentUnitsURL } from '../../../utils/urls'
import { getItem } from '../../../utils/localStorage';
function Dashboard() {

  const [units, setUnits] = useState([])

  const fetchResidentUnits = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const FacilityId = await getItem('selectedFacilityId');

      const response = await makeRequest2(residentUnitsURL + '/' + CustomerId + '/' + FacilityId, 'GET', {})
      if (response.success) {

        setUnits(response.data)
      }
      else {
        throw new Error(response.error)
      }
    }
    catch (err) {
      toastify(err.message, 'error')
    }
  }
  useEffect(() => {
    setTimeout(()=>{
      fetchResidentUnits()
    },1000)
   
  }, [])
  return (
    <Layout>
      <div className="row">
        <div className="col-12">

        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">

                <div className="flex-grow-1 ms-3">
                  <h6 className="mb-0">My Units</h6>
                </div>

              </div>
              <div className="row">
                {
                  units.map((unit, index) => {
                    return <div key={index + 1} className="col-md-3" style={{ borderColor: 'green', borderWidth: 2 }}>
                      <div className="bg-body p-3 mt-3 rounded">
                        <div className="mt-3 row align-items-center">

                          <div className="col-12">
                            <span>Unit Name: <strong className="mb-1">{unit.name}</strong></span>

                            <br />
                            <span>Unit Type:<strong className="mb-1"> {unit.unitType}</strong></span>
                            <br />

                            {
                              unit.customerType === 'Home Owner' && <span className="badge rounded-pill text-bg-info mt-2"><strong className="mb-1"> Home Owner </strong></span>
                            }
                            {
                              unit.customerType === 'Tenant' && <span className="badge rounded-pill text-bg-warning mt-2"><strong className="mb-1"> Tenant </strong></span>
                            }




                          </div>
                        </div>
                      </div>
                    </div>
                  })
                }


              </div>

            </div>
          </div>
        </div>




        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">Invoices</h5>

              </div>
              {/* <div id="total-income-graph" /> */}
              <div className="row g-3 mt-3">
                <div className="col-md-12 text-center">
                  <span>No Data Found</span>
                </div>
                {/* <div className="col-sm-6">
                  <div className="bg-body p-3 rounded">
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0">
                        <span className="p-1 d-block bg-danger rounded-circle">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <p className="mb-0">Overdue</p>
                      </div>
                    </div>
                    <h6 className="mb-0">
                      KES 120,000,000

                    </h6>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="bg-body p-3 rounded">
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0">
                        <span className="p-1 d-block bg-warning rounded-circle">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <p className="mb-0">Open</p>
                      </div>
                    </div>
                    <h6 className="mb-0">
                      KES 23,876

                    </h6>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="bg-body p-3 rounded">
                    <div className="d-flex align-items-center mb-2">
                      <div className="flex-shrink-0">
                        <span className="p-1 d-block bg-success rounded-circle">
                          <span className="visually-hidden">New alerts</span>
                        </span>
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <p className="mb-0">Paid</p>
                      </div>
                    </div>
                    <h6 className="mb-0">
                      KES 200,000,000

                    </h6>
                  </div>
                </div> */}

              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>

  )
}
export default Dashboard