
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import { getTransactionsURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
function PaymentHistory() {
  const [receipts, setReceipts] = useState([])
  const [filterReceipts, setFilteredReceipts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [byProject, setByProject] = useState(false)

  const getTransactions = async () => {
    try {
    //   const response = await makeRequest2(getTransactionsURL, 'GET', {})

    //   if (response.success) {
    //     setTransactions(response.data)
    //     setFilteredTransactions(response.data)
    //   }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  const HandeInputSearch = async (value) => {
    try {
      setSearchTerm(value)
      if (value === '') {
        getTransactions()
      }
      else {
        const results = await filterObjectsByAllProperties(filterReceipts, value);
        setReceipts(results)
      }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  useEffect(() => {
    getTransactions()
  }, [])

  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/payment_history"}>Payment History </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">


                    <div className="col-md-3 col-xs-12 mt-3">
                      <br />
                      <input className="form-control" placeholder="Search here" value={searchTerm} onChange={(e) => { HandeInputSearch(e.target.value) }} />

                    </div>
                    <div className="col-md-8 col-xs-12 mt-2">
                      <div className="row">
                        <div className="col-md-4 col-xs-5 mt-2">
                          <label>Start Date</label>
                          <input className="form-control" type="date" />
                        </div>
                        <div className="col-md-4 col-xs-5 mt-2">
                          <label>End Date</label>
                          <input className="form-control" type="date" />
                        </div>
                      
                      </div>


                    </div>

                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={receipts} header={header} emptyMessage="No receipts found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                      <Column field="ref" sortable header="Ref Number"></Column>
                      <Column field="payment_mode" sortable header="Payment Mode"></Column>
                      <Column field="amount" header="Amount"></Column>
                      <Column field="amount_paid"  header="Amount Paid"></Column>
                      <Column field="date" sortable header="Date"></Column>
                      <Column field=""  header="Actions"></Column>
                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>
      <Dialog header="Select A Project" visible={byProject} style={{ width: '50vw' }} onHide={() => { if (!byProject) return; setByProject(false); }}>
        <select className="form-control select">
          <option>-- Select --</option>
          <option value=""></option>

        </select>
      </Dialog>
    </Layout>
  )
}


export default PaymentHistory