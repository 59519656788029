
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import { getTransactionsURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
function StatementOfAccount() {
  const [receipts, setReceipts] = useState([])
  const [filterReceipts, setFilteredReceipts] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [byProject, setByProject] = useState(false)

  const getTransactions = async () => {
    try {
    //   const response = await makeRequest2(getTransactionsURL, 'GET', {})

    //   if (response.success) {
    //     setTransactions(response.data)
    //     setFilteredTransactions(response.data)
    //   }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  const HandeInputSearch = async (value) => {
    try {
      setSearchTerm(value)
      if (value === '') {
        getTransactions()
      }
      else {
        const results = await filterObjectsByAllProperties(filterReceipts, value);
        setReceipts(results)
      }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  useEffect(() => {
    getTransactions()
  }, [])

  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/statement_of_account"}>Statement of Accounts </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">


                    <div className="col-md-3 col-xs-12 mt-3">
                    
                      <label>Start Date:</label>
                      <br />
                      <br/>
                      <input  type="date" className="form-control" placeholder="" value={searchTerm} onChange={(e) => { HandeInputSearch(e.target.value) }} />

                    </div>
                    <div className="col-md-3 col-xs-12 mt-3">
                    
                    <label>End Date:</label>
                    <br />
                    <br/>
                    <input  type="date" className="form-control" placeholder="" value={searchTerm} onChange={(e) => { HandeInputSearch(e.target.value) }} />

                  </div>
                  <div className="col-md-3 col-xs-12 mt-3">
                    
                    <label>Unit</label>
                    <br />
                    <br/>
                    <select className="form-control">
                        <option value="">-- Select --</option>
                    </select>
                  </div>
                    <div className="col-md-3 col-xs-12 mt-3">
                      <br />
                      <br/>
                      <button className="btn btn-primary">Generate Statement</button>
                    </div>
                  

                  </div>

                </div>
               
              </div>
            </div>


          </div>

        </div>
      </div>
    
    </Layout>
  )
}


export default StatementOfAccount