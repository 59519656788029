
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../../utils/toast'
import Layout from '../../component/layout'
function PowerMeterInfo() {


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/power_utility"}>Power Utility </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Link to="/resident/power_utility">
                                                <i className="ti ti-arrow-left"></i>
                                                &nbsp;
                                                Back
                                            </Link>


                                        </div>

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body py-0">
                                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                                <i className="ti ti-dashboard me-2"></i>Meter Info
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                                <i className="ti ti-file-text me-2"></i>Meter Consumption
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-1" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                                <i className="ti ti-id me-2"></i>Account Info
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="true">
                                                                <i className="ti ti-circle-plus me-2"></i>Credits
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-5" role="tab" aria-selected="true">
                                                                <i className="ti ti-circle-minus me-2"></i>Debits
                                                            </a>
                                                        </li>



                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-xxl-3">
                                                            <div className="card">
                                                                <div className="card-body position-relative">
                                                                    <div className="position-absolute end-0 top-0 p-3">
                                                                        <span className="badge bg-success">On</span>
                                                                    </div>
                                                                    <div className="text-center mt-4">

                                                                        <h1 className="mb-0">20 kwh</h1>
                                                                        <p className="text-muted text-sm">Meter S/N: 12394032</p>

                                                                        <hr className="my-3 border border-secondary-subtle" />

                                                                        <hr className="my-3 border border-secondary-subtle" />
                                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3 text-center">

                                                                            <p className="mb-0">Updated: 2024-07-02 10:50</p>
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                                    <div className="row">

                                                        <div className="col-sm-12">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <div className="row">


         
                                                                        <div className="col-md-8 col-xs-12 mt-2">
                                                                            <div className="row">
                                                                                <div className="col-md-4 col-xs-5 mt-2">
                                                                                    <label>Start Date</label>
                                                                                    <input className="form-control" type="date" />
                                                                                </div>
                                                                                <div className="col-md-4 col-xs-5 mt-2">
                                                                                    <label>End Date</label>
                                                                                    <input className="form-control" type="date" />
                                                                                </div>
                                                                           
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="dt-responsive table-responsive">
                                                                        <DataTable value={[]} header={header} emptyMessage="No consumptions found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>

                                                                        
                                                                            <Column field="reading" sortable header="Reading (kwh)"></Column>
                                                                            <Column field="date" sortable header="Date"></Column>
                                                                            <Column field="time" sortable header="Time"></Column>
                                                                        </DataTable>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="profile-3" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-xxl-3">
                                                            <div className="card">
                                                                <div className="card-body position-relative">
                                                                    <div className="position-absolute end-0 top-0 p-3">
                                                                        <span className="badge bg-success">Prepaid</span>
                                                                    </div>
                                                                    <div className="text-center mt-4">

                                                                        <h1 className="mb-0">KES 400</h1>
                                                                        <p className="text-muted text-sm">Outstanding Balance: KES (230)<br />Account No: 15678</p>

                                                                        <hr className="my-3 border border-secondary-subtle" />

                                                                        <hr className="my-3 border border-secondary-subtle" />
                                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3 text-center">

                                                                            <p className="mb-0">Updated: 2024-07-02 10:50</p>
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-2">
                                                    <div className="row">

                                                        <div className="col-sm-12">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <div className="row">


         
                                                                        <div className="col-md-8 col-xs-12 mt-2">
                                                                            <div className="row">
                                                                                <div className="col-md-4 col-xs-5 mt-2">
                                                                                    <label>Start Date</label>
                                                                                    <input className="form-control" type="date" />
                                                                                </div>
                                                                                <div className="col-md-4 col-xs-5 mt-2">
                                                                                    <label>End Date</label>
                                                                                    <input className="form-control" type="date" />
                                                                                </div>
                                                                           
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="dt-responsive table-responsive">
                                                                        <DataTable value={[]} header={header} emptyMessage="No credits found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                            <Column field="amount_paid" sortable header="Amount Paid"></Column>
                                                                            <Column field="date" sortable header="Date"></Column>
                                                                            <Column field="time" sortable header="Time"></Column>
                                                                            <Column field="balance" header="Balance"></Column>
                                                                        </DataTable>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-5" role="tabpanel" aria-labelledby="profile-tab-2">
                                                    <div className="row">

                                                        <div className="col-sm-12">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <div className="row">


         
                                                                        <div className="col-md-8 col-xs-12 mt-2">
                                                                            <div className="row">
                                                                                <div className="col-md-4 col-xs-5 mt-2">
                                                                                    <label>Start Date</label>
                                                                                    <input className="form-control" type="date" />
                                                                                </div>
                                                                                <div className="col-md-4 col-xs-5 mt-2">
                                                                                    <label>End Date</label>
                                                                                    <input className="form-control" type="date" />
                                                                                </div>
                                                                           
                                                                            </div>


                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="dt-responsive table-responsive">
                                                                        <DataTable value={[]} header={header} emptyMessage="No debits found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>

                                                                        
                                                                            <Column field="amount_deducted"  header="Amount Deducted"></Column>
                                                                            
                                                                            <Column field="previous_reading" sortable header="Previous Reading (kwh)"></Column>
                                                                            <Column field="current_reading" sortable header="Current Reading (kwh)"></Column>
                                                                            <Column field="usage" sortable header="Usage"></Column>
                                                                            <Column field="date" sortable header="Date"></Column>
                                                                            <Column field="time" header="Time"></Column>
                                                                            <Column field="month" header="Month"></Column>
                                                                        </DataTable>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}


export default PowerMeterInfo