import React , { useState, useEffect } from 'react'
import Layout from '../component/layout'
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getResidentContractsURL } from '../../../utils/urls';
import { getItem } from '../../../utils/localStorage';

const Contracts = () => {
    const [contracts, setContracts] = useState([]);

  const fetchContracts = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      console.log("FacilityId", facilityId);
      const customerId = await getItem('selectedCustomerId');
      console.log("CustomerId", customerId);
      const response = await makeRequest2(getResidentContractsURL + '/' + facilityId + '/' + customerId, 'GET', {});
      console.log("Response", response);
      if (response.success) {
        setContracts(response.data.contracts);
        console.log("Contracts", response.data.contracts);
      } else {
        toastify('Failed to fetch contracts', 'error');
      }
    } catch (err) {
      toastify(err.message, 'error');
    }
  };

  useEffect(() => {
    fetchContracts();
  }, [])
  return (
    <Layout>
        <div className="page-header">
            <div className="page-block">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/app/">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item">Lease Management</li>
                            <li className="breadcrumb-item">Contracts</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Contracts</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3 mb-3">
                                <input
                                    className="form-control"
                                    placeholder="Search here"
                                    onChange={(e) => {
                                        const searchTerm = e.target.value.toLowerCase();
                                        const filtered = contracts.filter(contract =>
                                            contract.contractName.toLowerCase().includes(searchTerm)
                                        );
                                        setContracts(filtered);
                                    }}
                                />
                            </div>
                        </div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Starting Date</th>
                                    <th>Ending Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contracts.length > 0 ? (
                                    contracts.map((contract, index) => (
                                        <tr key={index}>
                                            <td>{contract.contractName}</td>
                                            <td>{new Date(contract.startDate).toISOString().split('T')[0]}</td>
                                            <td>{new Date(contract.endDate).toISOString().split('T')[0]}</td>
                                            <td>{contract.status}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            No contracts found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

}

export default Contracts