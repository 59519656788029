
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import { getTransactionsURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
function Feedback() {
    const [invoices, setInvoices] = useState([])
    const [filterInvoices, setFilteredInvoices] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [byProject, setByProject] = useState(false)

    const getTransactions = async () => {
        try {
            //   const response = await makeRequest2(getTransactionsURL, 'GET', {})

            //   if (response.success) {
            //     setTransactions(response.data)
            //     setFilteredTransactions(response.data)
            //   }
        }
        catch (error) {
            toastify(error.message, 'error')
        }
    }
    const HandeInputSearch = async (value) => {
        try {
            setSearchTerm(value)
            if (value === '') {
                getTransactions()
            }
            else {
                const results = await filterObjectsByAllProperties(filterInvoices, value);
                setInvoices(results)
            }
        }
        catch (error) {
            toastify(error.message, 'error')
        }
    }
    useEffect(() => {
        getTransactions()
    }, [])

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/feedback"}>Feedback</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Feedback</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">Message</label>
                                        <textarea type="text"  className="form-control" />
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                        <div className="card-footer text-end btn-page">
                                                            <div className="btn btn-outline-secondary">Cancel</div>
                                                            <div className="btn btn-primary">Submit</div>
                                                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Select A Project" visible={byProject} style={{ width: '50vw' }} onHide={() => { if (!byProject) return; setByProject(false); }}>
                <select className="form-control select">
                    <option>-- Select --</option>
                    <option value=""></option>

                </select>
            </Dialog>
        </Layout>
    )
}


export default Feedback