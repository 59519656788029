import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { getItem, clearStorage } from "../../../utils/localStorage";
import { residentFacilitiessURL } from "../../../utils/urls";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
function Nav() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [userName, setFullname] = useState("");
  const [userRole, setRole] = useState("");
  const [facilities, setFacilities] = useState([]);

  const HandleLogOut = async () => {
    await clearStorage();
    navigate("/");
  };
  const getCurrentUser = async () => {
    try {
      const currentUserValue = await getItem("RESIDENTUSER");
      if (currentUserValue) {
        const currentUser = JSON.parse(currentUserValue);
        const fullName = currentUser.user.fullName;
        const role = currentUser.user.type;
        setFullname(fullName);
        setRole(role);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const fetchResidentFacilities = async () => {
    try {
      const response = await makeRequest2(residentFacilitiessURL, "GET", {});

      if (response.success) {
        setFacilities(response.data);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.log(err);
      toastify(err.message, "error");
    }
  };
  useEffect(() => {
    getCurrentUser();
    fetchResidentFacilities();
  }, []);
  return (
    <nav className="pc-sidebar">
      <div className="navbar-wrapper">
        <div className="m-header">
          <Link to={"/resident/"} className="b-brand text-primary">
            <img
              src="/assets/images/PayServeLogoFinal.png"
              className="img-fluid logo-lg"
              alt="logo"
              style={{ width: 180 }}
            />
            <span className="badge bg-light-success rounded-pill ms-2 theme-version">
              v1.0
            </span>
          </Link>
        </div>
        <div className="navbar-content" style={{ overflowY: "scroll" }}>
          <div className="card pc-user-card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img
                    src="/assets/images/user/avatar-1.jpg"
                    alt="user-image"
                    className="user-avtar wid-45 rounded-circle"
                  />
                </div>
                <div className="flex-grow-1 ms-3 me-2">
                  <h6 className="mb-0">{userName}</h6>
                  <small>Resident</small>
                </div>
                <Link
                  className="btn btn-icon btn-link-secondary avtar"
                  data-bs-toggle="collapse"
                  to="#pc_sidebar_userlink"
                >
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-sort-outline" />
                  </svg>
                </Link>
              </div>
              <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                <div className="pt-3">
                  {/* <Link to={"/resident/settings"}>
                                        <i className="ti ti-settings" />
                                        <span>Settings</span>
                                    </Link> */}

                  <Link
                    to={"#!"}
                    onClick={() => {
                      HandleLogOut();
                    }}
                  >
                    <i className="ti ti-power" />
                    <span>Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ul className="pc-navbar mb-5">
            <li
              className={`pc-item ${
                currentPath === "/resident/"
                  ? "active"
                  : "" || currentPath === "/resident"
                  ? "active"
                  : ""
              }`}
            >
              <Link to={"/resident"} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-home" />
                  </svg>
                </span>

                <span className="pc-mtext">Dashboard</span>
              </Link>
            </li>

            {/* 
                        <li className="pc-item pc-caption">
                            <label>Statements</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/invoices'  ? 'active' : ''}`}>
                            <Link to={'/resident/invoices'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-notification-status" />
                                    </svg>
                                </span>
                                
                                <span className="pc-mtext">Invoices</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/receipts'  ? 'active' : ''}`}>
                            <Link to={'/resident/receipts'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-note-1" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Receipts</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/payment_history' ? 'active' : ''}`}>
                            <Link to={'/resident/payment_history'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-fatrows" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Payment History</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/statement_of_account' ? 'active' : ''}`}>
                            <Link to={'/resident/statement_of_account'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-notification-status" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Statement of Account</span>
                            </Link>
                        </li> */}

            {/* <li className="pc-item pc-caption">
                            <label>Utilities</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li> */}
            {/* <li className={`pc-item ${currentPath === '/resident/water_utility' ? 'active' : ''}`}>
                            <Link to={'/resident/water_utility'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-level" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Water</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/power_utility' ? 'active' : ''}`}>
                            <Link to={'/resident/power_utility'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-cpu-charge" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Power</span>
                            </Link>
                        </li> */}

            <li className="pc-item pc-caption">
              <label>Contracts</label>
              <svg className="pc-icon">
                <use xlinkHref="#custom-layer" />
              </svg>
            </li>

            <li
              className={`pc-item ${
                currentPath === "/resident/contract_management/contracts"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to={"/resident/contract_management/contracts"}
                className="pc-link"
              >
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-text-block" />
                  </svg>
                </span>
                <span className="pc-mtext">Contracts</span>
              </Link>
            </li>

            {/* <li className="pc-item pc-caption">
                            <label>Tickets</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/view_tickets' ? 'active' : ''}`}>
                            <Link to={'/resident/view_tickets'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-text-align-justify-center" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">View Tickets</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/raise_ticket' ? 'active' : ''}`}>
                            <Link to={'/resident/raise_ticket'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-text-block" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Raise Ticket</span>
                            </Link>
                        </li>
                       
                     
                        <li className="pc-item pc-caption">
                            <label>More</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-layer" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/feedback' ? 'active' : ''}`}>
                            <Link to={'/resident/feedback'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-share-bold" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Feedback</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/resident/resident_wall' ? 'active' : ''}`}>
                            <Link to={'/resident/resident_wall'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-message-2" />
                                    </svg>
                                </span>
                                <span className="pc-mtext">Resident Wall</span>
                            </Link>
                        </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Nav;
