
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getItem } from '../../../utils/localStorage';
import { getVisitLogsURL, acceptVisitRequestURL, denyVisitRequestURL } from '../../../utils/urls'
import formatISODate from '../../../utils/formatDate';
function DigitalLogs() {
  const [logs, setLogs] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const getDigitalLogs = async () => {
    try {
      const CustomerId = await getItem('selectedCustomerId');
      const response = await makeRequest2(getVisitLogsURL + '/' + CustomerId, 'GET', {});

      if (response.success) {
        setLogs(response.data.data);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, 'error')
    }
  }
  const HandeInputSearch = async (value) => {
    try {
      setSearchTerm(value)
      if (value === '') {
        getDigitalLogs()
      }
      else {
        const results = await filterObjectsByAllProperties(logs, value);
        setLogs(results)
      }
    }
    catch (error) {
      toastify(error.message, 'error')
    }
  }
  const nameTemplate = (rowData) => {
    return <Link to={'/resident/visitor_access/view_digital_log/' + rowData._id}>
      {rowData.visitorName}
    </Link>
  }
  const statusTemplate = (rowData) => {
    const status = rowData.status
    const statusStyles = {
      'Visit Confirmation': {
        color: '#ff9800',         // Orange
        backgroundColor: '#fff3e0' // Light Orange
      },
      'Scheduled': {
        color: '#4caf50',         // Green
        backgroundColor: '#e8f5e9' // Light Green
      },
      'Checked In': {
        color: '#2196f3',         // Blue
        backgroundColor: '#e3f2fd' // Light Blue
      },
      'Checked Out': {
        color: '#9c27b0',         // Purple
        backgroundColor: '#f3e5f5' // Light Purple
      },
      'Cancelled': {
        color: '#f44336',         // Red
        backgroundColor: '#ffebee' // Light Red
      }
    };



    return (
      <span
        style={{
          fontWeight: 'bold',
          padding: '5px 10px',
          borderRadius: '15px',
          ...statusStyles[status] // Apply styles based on status
        }}
      >
        {status}
      </span>
    );



  };
  const actionTemplate = (rowData) => {


    return (
      <div className="actions">

        {
          rowData.status === 'Visit Confirmation' && <>
            <Button
              icon="ti ti-check"
              className="p-button-rounded p-button-success mr-2"
              onClick={() => {
                handleConfirmVisit(rowData._id)

              }}
              tooltip="Confirm Visit"
            // Allow deletion only if the row is disabled
            />
            <Button
              icon="ti ti-x"
              className="p-button-rounded p-button-danger mr-2"
              onClick={() => {
                handleDenyVisit(rowData._id)
              }}
              tooltip="Deny Visit"
            // Allow deletion only if the row is disabled
            />
          </>

        }


      </div>



    );
  };
  const startTimeTemplate = (rowData)=>{

    if(rowData.startTime !== null){
      return formatISODate(rowData.startTime)
    }
    else{
      return `...`
    }
  }
  const endTimeTemplate = (rowData)=>{
    if(rowData.endTime !== null){
      return formatISODate(rowData.endTime)
    }
    else{
      return `...`
    }
    
  }
  const handleDenyVisit = async (visitId) => {
    try {
      const response = await makeRequest2(denyVisitRequestURL + '/' + visitId, 'GET', {});
      console.log(response.success)
      if (response.success) {
        toastify(response.data, 'success')
        getDigitalLogs()
      }
      {
        throw new Error(response.error)
      }
    }
    catch (err) {
    
    }

  }
  const handleConfirmVisit = async (visitId) => {
    try {
      const response = await makeRequest2(acceptVisitRequestURL + '/' + visitId, 'GET', {});
      if (response.success) {
        toastify(response.data, 'success')
        getDigitalLogs()
      }
      {
        throw new Error(response.error)
      }
    }
    catch (err) {
      
    }

  }
  useEffect(() => {
    getDigitalLogs()
  }, [])

  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
      <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
      <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/visitor_access/digital_logs"}>Digital Logs</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">


          <div className="row">

            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">


                    <div className="col-md-3 col-xs-12 mt-3">
                      <br />
                      <input className="form-control" placeholder="Search here" value={searchTerm} onChange={(e) => { HandeInputSearch(e.target.value) }} />

                    </div>


                  </div>

                </div>
                <div className="card-body">
                  <div className="dt-responsive table-responsive">
                    <DataTable value={logs} emptyMessage="No digital logs found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                      <Column header="Visitor Name" body={nameTemplate} />
                      <Column field="residentName" header="Resident Name" />
                      <Column field="houseNumber" header="House No" />
                      <Column field="status" header="Status" body={statusTemplate} sortable />
                      <Column body={startTimeTemplate} header="Checkin Time" />
                      <Column body={endTimeTemplate} header="Checkout Time" />
                      <Column body={actionTemplate} header="Actions" />

                    </DataTable>


                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>
      </div>

    </Layout>
  )
}


export default DigitalLogs