
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { Dialog } from 'primereact/dialog';
import { getTransactionsURL } from '../../../utils/urls'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
function ViewTickets() {
    const [invoices, setInvoices] = useState([])
    const [filterInvoices, setFilteredInvoices] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [byProject, setByProject] = useState(false)

    const getTransactions = async () => {
        try {
            //   const response = await makeRequest2(getTransactionsURL, 'GET', {})

            //   if (response.success) {
            //     setTransactions(response.data)
            //     setFilteredTransactions(response.data)
            //   }
        }
        catch (error) {
            toastify(error.message, 'error')
        }
    }
    const HandeInputSearch = async (value) => {
        try {
            setSearchTerm(value)
            if (value === '') {
                getTransactions()
            }
            else {
                const results = await filterObjectsByAllProperties(filterInvoices, value);
                setInvoices(results)
            }
        }
        catch (error) {
            toastify(error.message, 'error')
        }
    }
    useEffect(() => {
        getTransactions()
    }, [])

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/view_tickets"}>View Tickets</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-tabs invoice-tab border-bottom mb-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="analytics-tab-1" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-1-pane" type="button" role="tab" aria-controls="analytics-tab-1-pane"
                                        aria-selected="true">
                                        <span className="d-flex align-items-center gap-2">All <span
                                            className="avtar rounded-circle bg-light-primary">0</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-2" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-2-pane" type="button" role="tab" aria-controls="analytics-tab-2-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Open <span
                                            className="avtar rounded-circle bg-light-success">0</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-3" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-3-pane" type="button" role="tab" aria-controls="analytics-tab-3-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Ongoing <span
                                            className="avtar rounded-circle bg-light-warning">0</span></span>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="analytics-tab-4" data-bs-toggle="tab"
                                        data-bs-target="#analytics-tab-4-pane" type="button" role="tab" aria-controls="analytics-tab-4-pane"
                                        aria-selected="false">
                                        <span className="d-flex align-items-center gap-2">Resolved <span
                                            className="avtar rounded-circle bg-light-danger">0</span></span>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active mt-2" id="analytics-tab-1-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-1" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={[]} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketId" header="Ticket ID"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="description" header="Description"></Column>
                                            <Column field="status" header="Status"></Column>
                                            <Column field="date" header="Added On"></Column>


                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-2-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-2" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={[]} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketId" header="Ticket ID"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="description" header="Description"></Column>
                                            <Column field="date" header="Added On"></Column>
                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-3-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-3" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={[]} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketId" header="Ticket ID"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="description" header="Description"></Column>
                                            <Column field="date" header="Added On"></Column>


                                        </DataTable>
                                    </div>
                                </div>
                                <div className="tab-pane fade mt-2" id="analytics-tab-4-pane" role="tabpanel"
                                    aria-labelledby="analytics-tab-4" tabindex="0">
                                    <div className="table-responsive">
                                        <DataTable value={[]} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="ticketId" header="Ticket ID"></Column>
                                            <Column field="subject" header="Subject"></Column>
                                            <Column field="description" header="Description"></Column>
                                            <Column field="date" header="Added On"></Column>
                                            <Column field="date" header="Resolved On"></Column>


                                        </DataTable>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Select A Project" visible={byProject} style={{ width: '50vw' }} onHide={() => { if (!byProject) return; setByProject(false); }}>
                <select className="form-control select">
                    <option>-- Select --</option>
                    <option value=""></option>

                </select>
            </Dialog>
        </Layout>
    )
}


export default ViewTickets